exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-flavor-js": () => import("./../../../src/pages/flavor.js" /* webpackChunkName: "component---src-pages-flavor-js" */),
  "component---src-pages-flavors-js": () => import("./../../../src/pages/flavors.js" /* webpackChunkName: "component---src-pages-flavors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingredient-js": () => import("./../../../src/pages/ingredient.js" /* webpackChunkName: "component---src-pages-ingredient-js" */),
  "component---src-pages-ingredients-js": () => import("./../../../src/pages/ingredients.js" /* webpackChunkName: "component---src-pages-ingredients-js" */),
  "component---src-pages-vendor-js": () => import("./../../../src/pages/vendor.js" /* webpackChunkName: "component---src-pages-vendor-js" */),
  "component---src-pages-vendors-js": () => import("./../../../src/pages/vendors.js" /* webpackChunkName: "component---src-pages-vendors-js" */)
}

